<template>
	<div id="page-dashboard">
		<div class="graficos">
			<div class="col-12 topo">
				<div class="row row-topo">
					<div class="col-3 titulo">
						<p>DASHBOARD <v-icon>fas fa-chart-bar</v-icon></p>
					</div>
					<!-- filtros da nossa dashboard-->
					<div class="col-9 filtros">
						<v-form ref="form_filtros">
							<v-icon>fas fa-filter</v-icon>
							<v-select
								:items="setores"
								item-text="name"
								item-value="id"
								v-model="setores_selecionado"
								label="Setor"
								placeholder="Setor"
								background-color="white"
								hide-details
								outlined
								@change="changeAtendente(setores_selecionado)"
								style="width: 20%; font-size: 15px; font-weight: 600"
							/>							
							<v-select
								:items="atendente"
								item-text="user.name"
								item-value="user_id"
								v-model="atendente_selecionado"
								label="Atendente"
								placeholder="Atendente"
								background-color="white"
								hide-details
								outlined
								style="width: 22%; font-size: 15px; font-weight: 600"	
							/>
							<v-menu
								v-model="menu1"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
								max-width="290px"
								min-width="auto"
								>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="data_de_formatada"
										label= "De"
										persistent-hint
										prepend-icon="mdi-calendar"
										hide-details
										readonly
										v-bind="attrs"
										v-on="on"
										style="font-size: 14px; font-weight: 600"			
									></v-text-field>
								</template>
								<v-date-picker
									v-model="data_de"
									no-title
									color="#11263C"
									locale="pt-br"
									@input="menu1 = false"
								></v-date-picker>
							</v-menu>
							<v-menu
								v-model="menu2"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
								max-width="290px"
								min-width="auto"
								>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="data_ate_formatada"
										label= "Até"
										persistent-hint
										prepend-icon="mdi-calendar"
										hide-details
										readonly
										v-bind="attrs"
										v-on="on"
										style="font-size: 14px; font-weight: 600"			
									></v-text-field>
								</template>
								<v-date-picker
									v-model="data_ate"
									no-title
									color="#11263C"
									locale="pt-br"
									@input="menu2 = false"
								></v-date-picker>
							</v-menu>							
							<v-btn class="primary-button" raised small @click="searchFiltro">Filtrar </v-btn>
							<v-btn class="primary-button" style="margin-left: 5px" raised small @click="limparFiltro"> 
								Limpar
							<i class="fas fa-filter" style="color: #FFFFFF;"></i>
							</v-btn>
						</v-form>
					</div>					
				</div>
			</div>
			<!-- gráfico em linha da nossa dashboard -->
			<div class="row">
				<div class="col-7 grafico-atendimentos">				
					<Chart
						:id="'graficoTotalAtendimentos canvas'"
						:height="150"
						:type="'line'"
						:title="'Atendimentos Abertos'"
						:titleF="'Atendimentos Finalizados'"
						:labels="['Jan', 'Fev', 'Mar' , 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']"
						:data="dadosAbertos"
						:dataF="dadosFechados"
						:backgroundColor="'#F4C430'"
						:borderColor="'#F4C430'"
						:backgroundColorFinalizados="'#91f04d'"
						:borderColorFinalizados="'#91f04d'"
						:borderWidth="4"
						:primarycolor="'#F4D728'"
						:secondarycolor="'#F4C430'"
					/>
				</div>
				<div class="col-3 grafico-percentual">
					<Chart
						:height="150"
						:width="150"
						:id="'graficoPercentual'"
						:type="'doughnut'"
						:title="'Percentual'"
						:labels="['Abertos nos últimos dias', 'Aguardando o Cliente', 'Fechamentos em Potencial' , 'Atendimento Online']"
						:data="[atendimentosAtivos, aguardandoAtendimento, atendimentosFinalizados]"
						:backgroundColor="['#F7D728', '#eb3838', '#61fc1f']"
						:borderWidth="4"
						:atendimentosTotais="atendimentosTotais"
                		ref="grafico"
					/>					
				</div>
				<div class="col-2 legendas-percentual">
					<div class="col-12 div-abertos">
						<p class="abertos"><span>Atendimentos Ativos</span></p>
						<p class="abertos-ativos">{{atendimentosAtivos}} Ativos</p>
					</div>
					<div class="col-12 div-aguardando">
						<p class="aguardando"><span>Atendimentos pendentes</span></p>
						<p class="aguardando-ativos">{{aguardandoAtendimento}} Pendentes</p>
					</div>
					<div class="col-12 div-fechamentos">
						<p class="fechamentos"><span>Atendimentos Finalizados</span></p>
						<p class="fechamentos-ativos">{{atendimentosFinalizados}} Finalizados</p>
					</div>
					<div class="col-12 div-duracao">
						<p class="duracao"><span>Duração Média</span></p>
						<p class="duracao-ativos">{{duracaoAtendimentos}} Em média</p>
					</div>
				</div>
				<!-- gráficos com as porcentagens dos funcionário na dashboard -->
				<div v-if="this.atendimentosTotais != ''" class="col-12 grafico-porcentagem-ativa">
					<div class="row">
						<div class="col-3 dados-atendentes" v-for="(atendimento, i) in dados" :key="i">
							<div class="row">
								<div class="col-12">
									<v-card-title>
										<span class="titulo-atendentes">Dados Atendente</span>
									</v-card-title>
									<v-badge
										:content="0"
										:value="0"
										color="red"
										overlap
									>
										<v-list-item-avatar style="height: 80px; width: 80px; margin: 0">
											<v-img
												alt=""
												:src="bg_avatar_chat"
											>
												<v-avatar v-if="dados[i].online == true" class="d-flex justify-center align-center avatar_" size="50" padding="3" style="top: 15px; left: 9px; border: solid 2px #61fc1f;">
													<figure style="height: 50px; width: 50px;">
														<v-img class="img-responsive" alt="" :src="dados[i].avatar"/>
													</figure>
												</v-avatar>
												<v-avatar v-if="dados[i].online == false" class="d-flex justify-center align-center avatar_" size="50" padding="3" style="top: 15px; left: 9px; border: solid 2px #f0eb97;">
													<figure style="height: 50px; width: 50px;">
														<v-img class="img-responsive" alt="" :src="dados[i].avatar"/>
													</figure>
												</v-avatar>
											</v-img>
										</v-list-item-avatar>
									</v-badge>
									<p class="nome-atendente">Nome: <span></span><span>{{dados[i].nomeAtendente}}</span></p>
									<p class="total-porcentagem"><span>{{dados[i].atendimentosAtendente}}</span> Atendimentos</p>
								</div>
								<div class="col-10">
									<template>
										<v-slider
											v-model="dados[i].finalizadosAtendente"
											thumb-label="always"	
											:label="'Finalizados'"
											color="#61f430"
											rounded
                                        	readonly
										>
										</v-slider>
										<v-slider	
											v-model="dados[i].aguardandoRespostaAtendente"
											thumb-label="always"
											:label="'Aguardando Resp.'"	
											color="#eb3838"
											rounded
                                        	readonly
										>
										</v-slider>
										<v-slider
											v-model="dados[i].segundosMedia"	
											:label="dados[i].mediaAtendente"
											color="#38a3eb"
											rounded
                                        	readonly
										>
										</v-slider>
									</template>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// importa o store
	import store from '@/store'
	// importa Chart
    import Chart from '@/components/Chart.vue'
	// importa o moment
    import moment from 'moment'
	import BG_chat_avatar from "@/assets/images/bg-chat-avatar.png"

	// exporta o componente
	export default {
		// nome do componente
		name: 'PageDashboard',
		// componentes filhos
		components: {
            Chart
		},
		// dados do componente
		data: () => ({
			data_de: new Date().toISOString().substr(0, 10),			
			data_ate: new Date().toISOString().substr(0, 10),			
			menu1: false,			
			menu2: false,
			//variavel para os setores
			setores: [],	
			//variavel para armazenar o setor selecionado
			setores_selecionado: [],		
			//variavel para os atendentes
			atendente: [],	
			//variavel para armazenar o atendente selecionado		
			atendente_selecionado: [],
			// variável para armazenar os atendimentos abertos para o gráfico
			atendimentosAbertos: '',
			// variável para armazenar os atendimentos fechados para o gráfico
			atendimentosFechados: '',
			// variável para armazenar o total de atendimentos
			atendimentosTotais: '',
			// variável para armazenar os atendimentos ativos no momento
			atendimentosAtivos: '',
			// variável para armazenar os atendimentos que estão no aguardo
			aguardandoAtendimento: '',
			// variável para armazenar o total de atendimentos finalizados
			atendimentosFinalizados: '',
			// variável para armazenar a duração média dos atendimentos
			duracaoAtendimentos: null,
			// variável para armazenar os dados de cada atendente
			dados: [{
				avatar: '',
				online: '',
				nomeAtendente: '',
				atendimentosAtendente: '',
				finalizadosAtendente: '',
				aguardandoRespostaAtendente: '',
				mediaAtendente: '',
				segundosMedia: '',
			}],
			//variavel para  armazenar os dados do total de atendimentos abertos de cada mes:
			dadosAbertos: [],
			// variavel para armazenar os dados do total de atendimentos fechados de cada mes
			dadosFechados: [],
			// variável para armazenar o fundo da imagem do funcionário
			bg_avatar_chat: BG_chat_avatar,
			//variavel para os campos do filtro
			filters: {
				end_date: '',
				start_date: '',
				setores: '',
				atendente: '',
			},
		}),
		computed: {
			data_de_formatada () {
				return this.formatDate(this.data_de)
			},
			data_ate_formatada () {
				return this.formatDate(this.data_ate)
			},
		},
		watch: {
			'$store.getters.company_id':{
				handler (){
					this.init()
				},
			},
			'store.getters.workspace_id': {
				handler (){
					this.init()
				},
			},
		},
		// funções deste componente
		methods: {
			async init(){
				// função para pegar os setores
				await this.getSetores()
			},
			// função para pegar o total de atendimentos abertos da dashboard
			async getAtendimentosAbertos(){
				// coloca o componente como loading
				this.loading = await true
				// inicialmente limpa a variável local para caso já haja dados
				this.atendimentosAbertos = ''
				// faz um dispatch para uma action no vuex
				var resp = await store.dispatch('atendimentosAbertos', this.filters)
				// caso a requisição seja concluída com sucesso
				if(resp){
					// atribui os dados à variável local
					this.atendimentosAbertos  = await resp.dados
					let meses = this.atendimentosAbertos
					this.dadosAbertos = []
					for(let i = 0; i < meses.length; i++){
						this.dadosAbertos.push(Number(meses[i].rooms))
					}
				}
				// retira o loading do componente
				this.loading = await false
			},
			// função para pegar o total de atendimentos fechados da dashboard
			async getAtendimentosFechados(){
				// coloca o componente como loading
				this.loading = await true
				// inicialmente limpa a variável local para caso já haja dados
				this.atendimentosFechados = ''
				// faz um dispatch para uma action no vuex
				var resp = await store.dispatch('atendimentosFechados', this.filters)
				// caso a requisição seja concluída com sucesso
				if(resp){
					// atribui os dados à variável local
					this.atendimentosFechados = await resp.dados
					let mesesFechados = this.atendimentosFechados
					this.dadosFechados = []
					for(let i = 0; i < mesesFechados.length; i++){
						this.dadosFechados.push(Number(mesesFechados[i].rooms))
					}
				}
				// retira o loading do componente
				this.loading = await false
			},
			// função para pegar o total de chamados
			async getAtendimentosTotais(){
				// coloca o componente como loading
				this.loading = await true
				// inicialmente limpa a variável local para caso já haja dados
				this.atendimentosTotais = ''
				// faz um dispatch para uma action no vuex
				var resp = await store.dispatch('atendimentosTotais', this.filters)
				// caso a requisição seja concluída com sucesso
				if(resp){
					// atribui os dados à variável local
					this.atendimentosTotais = await resp.dados.length
				}
				// retira o loading do componente
				this.loading = await false
			},
			// função para pegar os chamados ativos
			async getAtendimentosAtivos(){
				// coloca o componente como loading
				this.loading = await true
				// inicialmente limpa a variável local para caso já haja dados
				this.atendimentosAtivos = ''
				// faz um dispatch para uma action no vuex
				var resp = await store.dispatch('atendimentosAtivos', this.filters)
				// caso a requisição seja concluída com sucesso
				if(resp){
					// atribui os dados à variável local
					this.atendimentosAtivos = await resp.dados.length
				}
				// retira o loading do componente 
				this.loading = await false
			},
			// função para pegar os chamados que ainda nao foram assumidos
			async getAguardandoAtendimento(){
				// coloca o componente como loading
				this.loading = await true
				// inicialmente limpa a variável local para caso já haja dados
				this.aguardandoAtendimento = ''
				// faz um dispatch para uma action no vuex
				var resp = await store.dispatch('aguardandoAtendimento', this.filters)
				// caso a requisição seja concluída com sucesso
				if(resp){
					// atribui os dados à variável local
					this.aguardandoAtendimento = await resp.dados.length
				}
				// retira o loading do componente
				this.loading = await false
			},
			// função para pegar os chamados finalizados 
			async getAtendimentosFinalizados(){
				// coloca o componente como loading
				this.loading = await true
				// inicialmente limpa a variável local para caso já haja dados
				this.atendimentosFinalizados = ''
				// faz um dispatch para uma action no vuex
				var resp = await store.dispatch('atendimentosFinalizados', this.filters)
				// caso a requisição seja concluída com sucesso
				if(resp){
					// atribui os dados à variável local 
					this.atendimentosFinalizados = await resp.dados.length
				}
				// retira o loading do componente
				this.loading = await false
			},
			// função para pegar a duração média dos atendimentos
			async getDuracaoAtendimentos(){
				// coloca o componente como loading
				this.loading = await true
				// inicialmente limpa a variável local para caso já haja dados
				this.duracaoAtendimentos = ''
				// faz um dispatch para uma action no vuex
				var resp = await store.dispatch('duracaoAtendimentos', this.filters)
				// caso a requisição seja concluída com sucesso
				if(resp){
					// atribui os dados à variável local
					this.duracaoAtendimentos = await (moment.utc(resp.dados[0].date_format * 1000).format('HH:mm:ss'))
				}
				// retira o loading do componente
				this.loading = await false
			},
			// função para pegar o total de atendimentos de cada atendente
			async getAtendimentosAtendente(){
				// coloca o componente como loading
				this.loading = await true
				// inicialmente limpa a variável local para caso já haja dados
				this.dados = [{
					online: '',
					avatar: '',
					nomeAtendente: '',
					atendimentosAtendente: '',
					finalizadosAtendente: '',
					aguardandoRespostaAtendente: '',
				}]
				// faz um dispatch para uma action no vuex
				var resp = await store.dispatch('atendimentosAtendente', this.filters)
				// caso a requisição seja concluída com sucesso
				if(resp.dados){
					for(let i = 0; i < resp.dados.length; i++){
						if(this.dados[i] == undefined){
							this.dados[i] = {
							}
						}
						let numero = i
						
						await this.getMediaAtendente(resp.dados[i].id, numero)

						this.dados[i].online = await resp.dados[i].online || ''
						if(resp.dados[i].avatar_file_id != null){
							this.dados[i].avatar = resp.dados[i].avatar.url
						}
						this.dados[i].nomeAtendente = await resp.dados[i].name || ''
						this.dados[i].atendimentosAtendente = await resp.dados[i].room_users.length || ''
						this.dados[i].finalizadosAtendente = await resp.dados[i].rooms.length || ''
						this.dados[i].aguardandoRespostaAtendente = await resp.dados[i].rooms_not_response.length || ''
					}
				}else{
					this.dados = [{
						online: '',
						avatar: '',
						nomeAtendente: '',
						atendimentosAtendente: '',
						finalizadosAtendente: '',
						aguardandoRespostaAtendente: '',
						mediaAtendente: '',
					}]
				}
				// retira o loading do componente
				this.loading = await false
			},
			// função para pegar a duração media dos atendimentos de cada atendente
			async getMediaAtendente(user_id, i){
				// inicialmente limpa a variável local para caso já haja dados
				this.dados[i].mediaAtendente = ''
				// coleta os dados 
				let dados = await {
					// coleta o usuario
					user: user_id
				}
				// faz um dispatch para uma action no vuex
				var resp = await store.dispatch('mediaAtendente', dados)
				// caso a requisição seja concluída com sucesso
				if(resp){
					// atribui os dados à variável local
					this.dados[i].mediaAtendente = await (moment.utc(resp.data.dados[0].date_format * 1000).format('HH:mm:ss'))
					this.dados[i].segundosMedia = await resp.data.dados[0].date_format % 10000
				}
			},
			// função para coletar os setores
			async getSetores(){
				// faz um dispatch para uma action no vuex para pegar os setores
				var resp = await store.dispatch('getSetoresSelect')
				// caso o status seja 200 (deu certo)
				if(resp.status == 200){
					// atribui os dados vindos do back a variavel setores
					this.setores = await resp.data.departments
				}								
			},
			// função para coletar os atendentes
			async changeAtendente(departamento){
				// coloca o componente como loading 
				this.loading = await true
				// coleta os dados 
				let dados = await {
					// coleta o departamento
					department: departamento
				}
				// faz um dispatch para uma action no vuex para pegar as atendentes passando o departamento
				var resp = await store.dispatch('getAtendente', dados)
				// caso a requisição seja concluída com sucesso
				if(resp.status == 200){
					// atribui os dados vindo do back à variável local
					this.atendente = (resp.data.user || [])
				}
				this.loading = await false
			},
			formatDate (date) {
				if (!date) return null

				const [year, month, day] = date.split('-')
				return `${day}/${month}/${year}`
			},
			// função de filtro da nossa dashboard 
			async searchFiltro(){
				// atribui o setor selecionado
				this.filters.setores = this.setores_selecionado
				// atribui o atendente selecionado
				this.filters.atendente = this.atendente_selecionado
				// atribui a data final selecionada
				this.filters.end_date = this.data_ate_formatada
				//atribui a data inicial selecionada
				this.filters.start_date = this.data_de_formatada
				// dados dos atendentes
				await this.getAtendimentosAtendente()
				// grafico em linha
				this.getAtendimentosAbertos()
				// grafico em linha
				this.getAtendimentosFechados()
				// grafico doughnut
				this.getAtendimentosTotais()
				// grafico doughnut
				this.getAtendimentosAtivos()
				// grafico doughnut
				this.getAguardandoAtendimento()
				// grafico doughnut
				this.getAtendimentosFinalizados()
				// grafico doughnut
				this.getDuracaoAtendimentos()
			},
			// função para limpar os campos do filtro
			limparFiltro(){
				// limpa os dados locais
				this.atendente_selecionado = []
				this.setores_selecionado = []
				this.data_de = new Date().toISOString().substr(0, 10)			
				this.data_ate = new Date().toISOString().substr(0, 10)
				this.dadosAbertos = ''
				this.dadosFechados = ''
				this.atendimentosTotais = ''				
				this.atendimentosAtivos = ''
				this.aguardandoAtendimento = ''
				this.atendimentosFinalizados = ''
				this.duracaoAtendimentos = null
				this.dados = [{
					online: '',
					avatar: '',
					nomeAtendente: '',
					atendimentosAtendente: '',
					finalizadosAtendente: '',
					aguardandoRespostaAtendente: '',
					mediaAtendente: '',
				}]
			},
		},
		// funções que rodam quando o componente é montado
		mounted(){
			// função de início do componente
			this.init()
		},
	}
</script>

<style lang="scss">
	#page-dashboard{
		display: flex;
		max-height: 100%;
		padding: 24px;
		flex-wrap: wrap;

		.graficos{
			background-color: #fff;
			border-radius: 10px;
			width: 100%;
			.topo{
				padding-bottom: 0;
				.titulo{
					padding-bottom: 0;
					p{
						color: #11263C;
						font-weight: 500;
						font-size: 14px;
						line-height: 58px;

						svg{
							font-size: 19px;
							margin: 0 4px;
							color: #040d16;
						}
						span{
							font-weight: 500;
							color: #D0D1D2;
						}
					}
				}
				.filtros{
					form{
						display: flex;
						flex-wrap: nowrap;
						justify-content: space-between;
						align-items: center;
						svg{
							font-size: 15px;
							color: $quaternarycolor;
						}
						.v-input{
							display: flex;
							flex-wrap: nowrap;
							justify-content: space-between;
							align-items: center;
							padding: 0 10px;
						}
					}
				}
			}
			.grafico-atendimentos{
				padding-top: 0;
			}
			.v-card__title{
				padding-left: 0;
			}
			.titulo-grafico{
				font-weight: 700;
				color: $quaternarycolor;
			}
			.titulo-atendentes{
				font-weight: 700;
				color: $quaternarycolor;
			}
			.legendas-percentual{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				padding: 50px 0;
				font-size: 12px;
				.div-abertos{
					padding-left: 0;
					.abertos{
						position: relative;
						color: #949494;
						font-size: 14px;
						margin: 0;
						span{
							margin-left: 40px;
						}
						&:before{
							content: '';
							width: 30px;
							height: 6px;
							position: absolute;
							background-color: #F7D728;
							border: 1px solid #F7D728;
							border-radius: 5px;
							top: 50%;
							transform: translateY(-50%);
						}
						@media (max-width: 1300px){
							font-size: 12px;
						}
					}
				}
				.div-aguardando{
					padding-left: 0;
					.aguardando{
						position: relative;
						color: #949494;
						font-size: 14px;
						margin: 0;
						span{
							margin-left: 40px;
						}
						&:before{
							content: '';
							width: 30px;
							height: 6px;
							position: absolute;
							background-color: #eb3838;
							border: 1px solid #eb3e38;
							border-radius: 5px;
							top: 50%;
							transform: translateY(-50%);
						}
						@media (max-width: 1300px){
							font-size: 12px;
						}
					}
				}
				.div-fechamentos{
					padding-left: 0;
					.fechamentos{
						position: relative;
						color: #949494;
						font-size: 14px;
						margin: 0;
						span{
							margin-left: 40px;
						}
						&:before{
							content: '';
							width: 30px;
							height: 6px;
							position: absolute;
							background-color: #61fc1f;
							border: 1px solid #61fc1f;
							border-radius: 5px;
							top: 50%;
							transform: translateY(-50%);
						}
						@media (max-width: 1300px){
							font-size: 12px;
						}
					}
				}
				.div-duracao{
					padding-left: 0;
					.duracao{
						position: relative;
						color: #949494;
						font-size: 14px;
						margin: 0;
						span{
							margin-left: 40px;
						}
						&:before{
							content: '';
							width: 30px;
							height: 6px;
							position: absolute;
							background-color: #1f70fc;
							border: 1px solid #1f70fc;
							border-radius: 5px;
							top: 50%;
							transform: translateY(-50%);
						}
						@media (max-width: 1300px){
							font-size: 12px;
						}
					}
				}
				.abertos-ativos,
				.aguardando-ativos,
				.fechamentos-ativos,
				.duracao-ativos{
					color: $quaternarycolor;
					font-weight: 600;
					font-size: 14px;
					margin-left: 40px;
				}
			}
			.grafico-porcentagem-ativa{
				padding-right: 30px;
				padding-left: 60px;
				.total-porcentagem{
					display: flex;
					align-items: center;
					color: #C4C4C4;
					font-size: 17px;
					span{
						font-size: 35px;
						font-weight: 700;
						color: $quaternarycolor;
						margin-right: 10px;
					}
				}
				.nome-atendente{
					display: flex;
					align-items: center;
					color: #C4C4C4;
					font-size: 17px;
					span{
						font-size: 20px;
						font-weight: 700;
						color: $quaternarycolor;
						margin-right: 10px;
					}
				}
				.online{
					display: flex;
					align-items: center;
					color: #C4C4C4;
					font-size: 17px;
					span{
						margin-left: 10px;
						margin-right: 4px;
						color: #040d16;
						font-size: 22px;
					}
				}
				.offline{
					display: flex;
					align-items: center;
					color: #C4C4C4;
					font-size: 17px;
					span{
						margin-left: 10px;
						margin-right: 4px;
						color: #5b5e616c;
						font-size: 22px;
					}
				}
				.v-progress-linear{
					margin-top: 30px;
					border-radius: 10px;
				}
				.legendas{
					margin-top: 15px;
					.atendimentos-novos, .atendimentos-fechados, .atendimentos-duracao{
						padding: 0;
					}
					.novos, .fechados, .duracao{
						position: relative;
						color: #C4C4C4;
						font-size: 14px;
						margin: 0;
						span{
							margin-left: 40px;
						}
						&:before{
							content: '';
							width: 30px;
							height: 4px;
							position: absolute;
							background-color: #61f430;
							border: 1px solid #61f430;
							border-radius: 5px;
							top: 50%;
							transform: translateY(-50%);
						}
					}
					.fechados{
						&:before{
							background-color: #eb3e38;
							border: 1px solid #eb3e38;
						}
					}
					.duracao{
						&:before{
							background-color: #0b59eb;
							border: 1px solid #0b59eb;
						}
					}
					.novos-ativos, .fechados-ativos, .duracao-ativos{
						color: $quaternarycolor;
						font-weight: 600;
						font-size: 15px;
						margin-left: 40px;
					}
				}
			}
		}
	}
	
	.grafico-atendimentos canvas{
		max-height: 30vh !important;
	}
	canvas#graficoPercentual {
		height: 30vh !important;
		width: 30vh !important;
		text-align: center;
		margin: auto;
	}
</style>